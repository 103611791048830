<template>
  <div class="home">
    <BusinessDashboard msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import BusinessDashboard from '@/components/BusinessDashboard.vue'

export default {
  name: 'Home',
  components: {
    BusinessDashboard
  }
}
</script>
