<template>
  <v-card
    class="elevation-0 top-toolbar-application"
    color="white"
  >
    <!-- <v-toolbar dense> -->
    <v-app-bar
      absolute
      dense
      color="white"
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
          >
             {{user.name}}<unicon name="angle-down" fill="#000000"></unicon>
          </v-btn>
        </template>

        <v-card>
          <v-list dense>
            <v-list-item>
              <v-list-item-avatar>
                <img
                  src="https://picsum.photos/1920/1080?random"
                  alt="image"
                >
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Company Name</v-list-item-title>
                <v-list-item-subtitle>Company City</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon>edit</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          
          <v-list dense>
            <v-list-item
              v-for="(item, i) in itemsUserMenu"
              :key="i"
              link
            >
              <template v-if="!item.isDivider">
                <v-list-item-avatar>
                  <unicon :name="item.icon" fill="#000000"></unicon>
                </v-list-item-avatar>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </template>
              <v-divider v-else></v-divider>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list dense>
            <v-list-item
              @click="logout"
            >
              <v-list-item-avatar>
                  <unicon name="signout" fill="#000000"></unicon>
              </v-list-item-avatar>
              <v-list-item-title>Cerrar sesión</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
<!-- 
      <v-btn @click="logout" icon color="primary">
        <unicon name="signout" fill="#000000"></unicon>Salir
      </v-btn> -->
    <!-- </v-toolbar> -->
    </v-app-bar>
  </v-card>
</template>
    
<script>
export default {
    data: () => ({
      itemsUserMenu: [
        { text: 'Editar negocio', icon: 'edit' },
        { text: 'Ayuda', icon: 'question-circle' },
        { text: 'Términos de uso', icon: 'book-open' },
        { text: 'Política de privacidad', icon: 'shield-check' },
      ],
    }),
    methods: {
      logout(){
        this.$store.dispatch('logout');
      },
    },
    computed:{
      user(){
        return this.$store.state.user;
      },
    },
}
</script>