<template>
  <v-container class="bc">

      <v-col class="mb-4">
        <h1 class="display-1 font-weight-bold mb-3 mt-3">
           Dashboard
        </h1>
      </v-col>

<div class="container-dash mb-4">

  <v-col>
        <h3 class="font-weight-bold mb-3">
           Registros
        </h3>
      </v-col>

    <v-row class="text-center ">
      <v-col cols="3">
        <v-col cols="12">
          <h3 class="font-weight-bold">
          {{business}}
          </h3>
        </v-col>
        <span class="mb-3 lbl-text">
           Negocios
        </span>
      </v-col>
       <v-col cols="3">
         <v-col cols="12">
          <h3 class="font-weight-bold">
           {{terminals}}
          </h3>
        </v-col>
        <span class="mb-3 lbl-text">
           Puntos de venta
        </span>
      </v-col>
      <v-col cols="3">
        <v-col cols="12">
          <h3 class="font-weight-bold">
           {{numTags}}
          </h3>
        </v-col>
        <span class="mb-3 lbl-text">
           Pulseras activadas
        </span>
      </v-col>
      <v-col cols="3">
        <v-col cols="12">
          <h3 class="font-weight-bold">
           {{transaction}}
          </h3>
        </v-col>
        <span class="mb-3 lbl-text">
           Transacciones
        </span>
      </v-col>

    </v-row>

    <v-row class="text-center ">
       <v-col cols="12">
          <span class="mb-3 lbl-text">
              Activaciones por mes
          </span>
       </v-col>
      
    </v-row>

     <v-row class="text-center ">
       <v-col cols="12">
          <div class="ct-chart">
            <canvas id="myChart" class="chart">
            </canvas>
          </div>
      </v-col>

    </v-row>


</div>
<div class="container-dash mb-4">

  <v-col>
    <h3 class="font-weight-bold mb-3">
      Operaciones
    </h3>
  </v-col>

 <v-row class="text-center ">
      <v-col cols="3">
        <v-col cols="12">
          <h3 class="font-weight-bold">
           ${{recharge[0] + recharge[1] + recharge[2]}}
          </h3>
        </v-col>
        <span class="mb-3 lbl-text">
           Recargas totales
        </span>
      </v-col>
       <v-col cols="3">
         <v-col cols="12">
          <h3 class="font-weight-bold">
           ${{payment}}
          </h3>
        </v-col>
        <span class="mb-3 lbl-text">
           Cobros totales
        </span>
      </v-col>
      <v-col cols="3">
        <v-col cols="12">
          <h3 class="font-weight-bold">
            ${{refund[0] + refund[1]}}
          </h3>
        </v-col>
        <span class="mb-3 lbl-text">
           Reembolsos totales
        </span>
      </v-col>
      <v-col cols="3">
        <v-col cols="12">
          <h3 class="font-weight-bold">
           $0
          </h3>
        </v-col>
        <span class="mb-3 lbl-text">
           Prepagos totales
        </span>
      </v-col>

    </v-row>





    <v-row class="text-center ">
        <v-col cols="6">
          <span class="mb-3">
           Recargas
          </span>
          <v-col cols="12">
              <div class="ct-chart">
                <canvas id="myChartdonut1" class="chart">
                </canvas>
              </div>


              <v-row class="text-left mt-3">
                <v-col cols="4">
                  <div class="mb-1 color-indiator color1"></div>
                  <p class="mb-0 amount-text">
                    ${{recharge[0]}}
                  </p>
                  <span class="mb-3 lbl-text">
                    Efectivo
                  </span>
                </v-col>
                <v-col cols="4">
                  <div class="mb-1 color-indiator color2"></div>
                  <p class="mb-0 amount-text">
                    ${{recharge[1]}}
                  </p>
                  <span class="mb-3 lbl-text">
                    Tarjeta
                  </span>
                </v-col>
                <v-col cols="4">
                  <div class="mb-1 color-indiator color3"></div>
                  <p class="mb-0 amount-text">
                    ${{recharge[2]}}
                  </p>
                  <span class="mb-3 lbl-text">
                    Prepago
                  </span>
                </v-col>
              </v-row>
              
                         

          </v-col>
          <v-col cols="12">
              
          </v-col>
        </v-col>

        <v-col cols="6">
          <span class="mb-3">
           Reembolsos
          </span>
          <v-col cols="12">
              <div class="ct-chart">
                <canvas id="myChartdonut2" class="chart">
                </canvas>
              </div>

            <v-row class="text-left mt-3">
               <v-col cols="4">
                  
                </v-col>
                <v-col cols="4">
                  <div class="mb-1 color-indiator color1"></div>
                  <p class="mb-0 amount-text">
                    ${{refund[0]}}
                  </p>
                  <span class="mb-3 lbl-text">
                    Efectivo
                  </span>
                </v-col>
                <v-col cols="4">
                  <div class="mb-1 color-indiator color2"></div>
                  <p class="mb-0 amount-text">
                    ${{refund[1]}}
                  </p>
                  <span class="mb-3 lbl-text">
                    Transferencia
                  </span>
                </v-col>
              </v-row>
              


          </v-col>
        </v-col>

    </v-row>
</div>

  </v-container>
  
</template>


<script>
import axios from 'axios'
import Chart from 'chart.js'

export default {

    data(){
        return{
        business : '0',
        terminals : '0',
        numTags : 0,
        tags:[],
        transaction:0,
        recharge:[0,0,0],
        refund:[0,0],
        dataActivate:[0,0,0,0,0,0,0,0,0,0,0,0],
        myChart:null,
        chartRecharge:null,
        chartRefound:null,
        payment:0
        }
    },

    methods:{

      getData(){
        let me=this;
        axios.get('business/analitics').then(function (response){
            me.business=response.data.business;
            me.terminals=response.data.terminals;
            me.tags=response.data.active;
            me.transaction = response.data.recharge.length
            response.data.active.map(item =>{
              me.numTags =me.numTags + item.numero;
              me.dataActivate[item._id.mes-1] = item.numero;
              me.myChart.update();
            });

            response.data.payment.map(item =>{
                me.payment= me.payment+ item.amount
            });

            response.data.recharge.map(item =>{

              if(item.payment_method.method_id=="cash")
                me.recharge[0]= item.amount + me.recharge[0];

              if(item.payment_method.method_id=="card")
                me.recharge[1]= item.amount + me.recharge[1];

              if(item.payment_method.method_id=="prepaid")
                me.recharge[2]= item.amount + me.recharge[2];
              me.chartRecharge.update();
            });

            response.data.refound.map(item =>{

              if(item.refund_method.method_id=="cash")
                me.refund[0]= item.amount + me.refund[0];

              if(item.refund_method.method_id=="transfer")
                me.refund[1]= item.amount + me.refund[1];

              me.chartRefound.update();
            });

        }).catch(function(error){
            console.log(error);
        });
      },

      graficar(){
        let me=this;
        var ctx = document.getElementById('myChart');
        me.myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
                datasets: [{
                    label: 'Activaciones por mes',
                    data: me.dataActivate,
                   backgroundColor: "rgba(0,237,213,1)",
                    borderColor: "rgba(0,237,213,1)",
                    borderWidth: 1
                }]
            },
             options: {
              legend: {
                  display: false
              },
            }
        });
      },

      DonaRecharge(){
        let me=this;
        var ctx = document.getElementById('myChartdonut1');
        
        me.chartRecharge = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: ["Efectivo","Tarjeta","Prepago"],
                datasets: [{
                    label: 'Activaciones por mes',
                    data: me.recharge,
                    backgroundColor: [
                        'rgba(0,237,213,1)',
                        'rgba(121,84,194,1)',
                        'rgba(179,179,179)'
                    ],
                    borderColor: [
                        'rgba(0,237,213,1)',
                        'rgba(121,84,194,1)',
                        'rgba(179,179,179)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
              legend: {
                  display: false
              },
            }
            
        });
        
      },

      DonaRefound(){
        let me=this;
        var ctx = document.getElementById('myChartdonut2');
        me.chartRefound = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: ["Efectivo","Transferencia"],
                datasets: [{
                    label: 'Activaciones por mes',
                    data: me.refund,
                    backgroundColor: [
                        'rgba(0,237,213,1)',
                        'rgba(121,84,194,1)',
                    ],
                    borderColor: [
                        'rgba(0,237,213,1)',
                        'rgba(121,84,194,1)',
                        'rgba(179,179,179)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
              legend: {
                  display: false
              },
            }

        });
      }









    },
    mounted(){

       console.log("=====XXX");
       console.log(this.$store.state.user.role);
        if(this.$store.state.user.role == 'admin')
          this.$store.dispatch('savePath', 'businessDashboard');
       console.log("=====XXX");
       console.log(this.$store.state.user.role);


      this.getData();
      this.graficar();
      this.DonaRecharge();
      this.DonaRefound();
    }
  
}


</script>

<style>
.bc{
  width: 100vw;
}

.chart{
  width: 100%;
}

.container-dash{
  background: white;
  width: 100%;
  padding: 10px;
}

.color-indiator{
  width: 20px;
  height: 8px;
  border-radius: 4px;
 
}
.color1{background: #00EDD5;}
.color2{background: #7954C2;}
.color3{background: #B3B3B3;}
.amount-text{
  color: black;
  font-weight: bold;
  font-size: 14px;
}

.lbl-text{
  color: #5A5A5A;
  font-size: 14px;
}


</style> 
