import { render, staticRenderFns } from "./Prepaids.vue?vue&type=template&id=0352dee6&scoped=true&"
import script from "./Prepaids.vue?vue&type=script&lang=js&"
export * from "./Prepaids.vue?vue&type=script&lang=js&"
import style0 from "./Prepaids.vue?vue&type=style&index=0&id=0352dee6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0352dee6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VContainer,VDataTable,VDatePicker,VFlex,VLayout,VMenu,VTextField})
