var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{staticClass:"mt-12",attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-card',{attrs:{"flat":"","color":"background"}},[_c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('transactions-toolbar',{attrs:{"title":"Transacciones","placeholderSearch":"Buscar","date":_vm.date,"business":_vm.activeBusiness},on:{"searchItem":_vm.searchItemData,"filterChanged":_vm.filterWasChanged}}),_c('v-layout',{attrs:{"row":"","wrap":"","white":""}},[_c('v-flex',{attrs:{"lg3":"","md3":"","xl3":"","sm3":""}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedDateFormattedMomentjs,"readonly":"","persistent-hint":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","color":"primary"},on:{"change":_vm.dateSelected,"input":function($event){_vm.menu1 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-flex',{attrs:{"lg12":"","md12":"","xl12":"","sm12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.transactions,"items-per-page":10,"item-key":"transaction_id","sort-by":"transaction_id","group-by":"tag_id","show-group-by":""},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedDate(item.createdAt))+" ")]}},{key:"item.transaction_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.transaction_type.name)+" ")]}},{key:"item.payment_method",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.payment_method.name)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.amount < 0 ? 'color:red;' : '')},[_vm._v(_vm._s(item.amount < 0 ? '-' : '')+" $ "+_vm._s(Math.abs(item.amount).toFixed(2)))])]}},{key:"item.tag_balance",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.tag_balance < 0 ? 'color:red;' : '')},[_vm._v(_vm._s(item.tag_balance < 0 ? '-' : '')+" $ "+_vm._s(Math.abs(item.tag_balance).toFixed(2)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status == 1 ? 'Realizado' : 'Cancelado')+" ")]}}])})],1)],1),_c('confirm-modal',{ref:"confirm",on:{"interface":_vm.componentConfirmResult}}),_c('transaction-info-drawer',{ref:"drawerTransaction"})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }