import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode';
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || null,
    user: null,
    route: null,
    business: JSON.parse(localStorage.getItem('business')) || null,
  },
  mutations: {
    setToken(state, token){
      state.token = token;
    },
    setUser(state, user){
      state.user = user;
    },
    setPath(state, route){
      state.route = route;
    },
    setBusiness(state, business){
      state.business = business;
    }
  },
  actions: {
    savePath({commit}, path) {
      commit("setPath", path)
      localStorage.setItem('route', path)
      
      const currentPath = router.history.current.path == '/' ? '/home' : router.history.current.path; 
      if (currentPath !== `/${path}`) router.push({name: path})
      
    },
    saveBusiness({commit}, business){
      commit("setBusiness", business)
      localStorage.setItem('business', JSON.stringify(business))

      if(business){
        const pathBusiness = 'businessDashboard';
        commit("setPath", pathBusiness)
        localStorage.setItem('route', pathBusiness)
        router.push({name: pathBusiness})
      }
    },
    clearBusiness({ commit }){
      commit("setBusiness", null)
      localStorage.removeItem('business')

      const pathBusiness = 'business';
      commit("setPath", pathBusiness)
      localStorage.setItem('route', pathBusiness)
      router.push({name: pathBusiness})

    },
    saveToken({ commit }, token){//LLamada inmediatamente despues de iniciar sesion
      commit("setToken", token)
      commit("setUser", decode(token))

      const initialRoute = 'home';
      commit("setPath", initialRoute)

      localStorage.setItem('route', initialRoute)
      localStorage.setItem('token', token)
    },
    autoLogin({ commit }){
      const token = localStorage.getItem('token')
      const currentRoute = localStorage.getItem('route')
      if(token){
        commit("setToken", token)
        commit("setUser", decode(token))
      }
      router.push({name: currentRoute})
    },
    logout({ commit }){
      commit("setToken", null)
      commit("setUser", null)
      commit("setBusiness", null)
      localStorage.removeItem('token')
      localStorage.removeItem('route')
      localStorage.removeItem('business')
      router.push({name: 'login'})
    }
  },
  getters : {
    isAuthenticated: state => !!state.token && state.user,
    isLoggedIn: state => !!state.token && state.user,
    isMaster: state => state.user && state.user.role == 'master',
    business: state => state.business,
    authStatus: state => state.status,
  },
  modules: {
  }
})
